.header_profile {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    width: 43px;
    background: white !important;
    border: 2px solid black !important;
    height: 43px;
    cursor: pointer;
}

.author_list_pp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 36px;
    width: 40px;
    background: white;
    margin: 0 !important;
    padding: 0 !important;

    canvas {
        border-radius: 50px;
        border: 2px solid gray;
    }
}

.lazy.nft__item_preview {
    max-height: 300px !important;
}

.header-wrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    .header-main {
        height: 72px !important;
        display: flex;
        align-items: center;
        height: 100%;
        -webkit-box-pack: justify;
        // justify-content: space-between;

        .logo {
            padding-right: 189px !important;
            img {
                height: 27px;
                width: auto !important;
            }
        }
    }

    .search input {
        width: 400px;
        height: 42px;
    }
}

.form-control {
    cursor: text;
    display: flex;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid rgb(229, 232, 235);
    width: 100%;
    padding: 12px;
    margin: 0;

    &:focus {
        box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
        border: none;
        outline: none;
    }
}

.btn {
    display: inline-flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 20px;
    border-radius: 10px;

    &:hover {
        transition: all 0.2s ease 0s;
        box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
    }

    &::before {
        display: none;
    }

    &-main {
        background-color: rgb(32, 129, 226);
        border: 1px solid rgb(32, 129, 226);
        color: rgb(255, 255, 255);
        &:hover {
            opacity: 0.7 ;
        }
    }

    &-out-main {
        border: 1px solid rgb(24, 104, 183);
        color: rgb(24, 104, 183);
        background-color: rgb(255, 255, 255);

        &:hover {
            border: 1px solid rgb(24, 104, 183);
            color: rgb(24, 104, 183);
        }
    }

}


// HOME PAGE
.nft_pic {
    background-color: rgb(251, 253, 255);
    border-radius: 10px;
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 10px 0px;

    &:hover {
        box-shadow: rgb(4 17 29 / 5%) 0px 0px 10px 0px;
    }
}


// I18N
.flag-container {
    display: flex;
    border: 1px solid rgb(229, 232, 235);
    height: 33px;
    padding: 0 5px;
    line-height: 33px;

    .flag {
        cursor: pointer;
        &.selected {
            font-weight: bold;
        }
    }
}

.detail-left {
    .image-wrapper {
        margin: 20px;
        border-radius: 10px;
        border: 1px solid rgb(229, 232, 235);
        overflow: hidden;

        img {
            margin: 0;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            border-radius: initial !important;
        }

        .image-title {
            -webkit-box-align: center;
            align-items: center;
            display: flex;
            font-weight: 500;
            padding: 12px;
            height: 42px;
            width: 100%;
            background: rgb(255, 255, 255);
            -webkit-box-pack: justify;
            justify-content: space-between;
            border-bottom: 1px solid rgb(229, 232, 235);
        }
    }
    .image-description {
        margin: 20px;
        border-radius: 10px;
        border: 1px solid rgb(229, 232, 235);
        overflow: hidden;
        color: rgb(53, 56, 64);

        .description {
            border-top: none;
            border-left: none;
            border-right: none;
            margin-bottom: -1px;
            margin-top: 1px;

            header {
                width: 100%;
                -webkit-box-align: center;
                align-items: center;
                border-radius: 10px;
                cursor: pointer;
                display: flex;
                font-size: 16px;
                font-weight: 600;
                padding: 20px;
                user-select: none;
                background-color: rgb(255, 255, 255);
            }

            .main {
                border-top: 1px solid rgb(229, 232, 235);
                color: rgb(53, 56, 64);
                background-color: rgb(251, 253, 255);

                div {
                    display: inline-flex;
                    -webkit-box-align: center;
                    align-items: center;
                    height: 24px;
                    width: 100%;
                    height: 32px;
                    padding: 0px 30px;
                    color: rgb(138, 147, 155);
                    font-size: 15px;
                    margin-top: 20px;

                    a {
                        margin-left: 10px;
                    }
                }

                p {
                    height: initial;
                    max-height: 200px;
                    overflow: auto;
                    text-align: left;
                    padding: 30px;
                }
            }
        }
    }
}

.detail {
    .item {
        &--header {
            margin: 20px 20px 15px 0;
            display: flex;
            flex-flow: column wrap;
            -webkit-box-pack: justify;
            justify-content: space-between;
        }

        &--counts {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            margin-top: 22px;
            color: rgb(112, 122, 131);
            font-size: 15px;
            font-weight: 400;

            &>div {
                margin-top: 8px;
                margin-bottom: 8px;
                &>div {
                    display: inline-flex;
                    -webkit-box-align: center;
                    align-items: center;
                    height: 24px;
                    width: 100%;

                    span {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        color: rgb(32, 129, 226);
                        text-decoration: none;
                        font-weight: inherit;
                    }
                }
            }
        }

        &--title {
            color: rgb(53, 56, 64);
            font-size: 30px;
            font-weight: 600;
            max-width: 100%;
            margin: 0px;
            width: 588px;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: normal;
        }

        &--collection-info {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-bottom: 5px;
            max-width: 100%;
        }
        &--collection-detail {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            max-width: 100%;
            width: 420px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            a {
                font-size: 16px;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                color: rgb(32, 129, 226);
                text-decoration: none;
                white-space: nowrap;
                font-weight: 500;
            }
        }
        &--collection-toolbar-wrapper {
            &>div {
                width: fit-content;
                &>button {
                    display: inline-flex;
                    flex-direction: row;
                    -webkit-box-align: center;
                    align-items: center;
                    border-radius: 10px;
                    -webkit-box-pack: center;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 10px;
                    background-color: rgb(255, 255, 255);
                    border: 1px solid rgb(229, 232, 235);
                    color: rgb(112, 122, 131);
                    border-radius: 0px;

                    &:first-child {
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                        border-right: none;
                    }
                    &:last-child {
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                        border-left: none;
                    }
                }
            }
        }

        &--frame {
            margin-top: 20px;
            section {
                border-radius: 10px;
                border: 1px solid rgb(229, 232, 235);
                overflow: hidden;
                display: block;
                padding: 0;
            }

            &--head {
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                border-bottom: 1px solid rgb(229, 232, 235);
                color: rgb(112, 122, 131);
                font-weight: initial;
                padding: 20px;
                background-color: rgb(255, 255, 255);
            }

            &--main {
                padding: 20px;
                background-color: rgb(251, 253, 255);
                color: rgb(53, 56, 64);

                .label {
                    color: rgb(112, 122, 131);
                    font-size: 15px;
                    font-weight: 400;
                }

                .price-container {
                    .price-main {
                        font-size: 30px;
                        font-weight: 600;
                        color: rgb(53, 56, 64);
                    }
                }

                button {
                    i {
                        margin-right: 10px;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

.hot-collection-image {
    margin: 0 auto;
    object-fit: cover;

}
